<template>
  <div id="account-management">
    <NForm inline label-placement="left">
      <NFormItem label="系统名称：">
        <NInput
          maxlength="50"
          show-count
          clearable
          v-model:value="formValue.system_name"
          @update:value="ChangeTitle"
        />
      </NFormItem>
      <NFormItem label="类型：">
        <div style="width: 200px">
          <NSelect :options="schoolTypeOptions" v-model:value="formValue.school_type_id" @update:value="ChangeTitle"/>
        </div>
      </NFormItem>
      <NFormItem label="状态：">
        <div style="width: 200px">
          <NSelect :options="typeOptions" v-model:value="formValue.status" @update:value="ChangeTitle"/>
        </div>
      </NFormItem>
      <NFormItem label="根据学生/教师账号查询学校：">
        <NInput
          clearable
          v-model:value="formValue.search_school"
          @update:value="ChangeTitle"
        />
      </NFormItem>
      <NFormItem>
        <NSpace>
          <NButton type="primary" @click="handleSearch">搜索</NButton>
          <NButton @click="handleClear">清除</NButton>
        </NSpace>
      </NFormItem>
    </NForm>

    <div class="account-null-btn">
      <NButton v-if="ownUrlPermission('school/school-email')" type="primary" @click="handleIPEmailSet">IP邮件设置</NButton>
    </div>
    <n-data-table
      remote
      ref="table"
      :single-line="false"
      :bottom-bordered="true"
      :columns="columns"
      :data="Datas"
      :loading="false"
      :pagination="pagination"
      @update:page-size="handlePageChange"
      @update:page="handlePageSizeChange"
    />
  
    <!-- 调整存储大小弹窗 -->
    <div id="StoragePopup">
      <n-modal
        class="custom-card"
        v-model:show="showModal"
        preset="card"
        :style="bodyStyle"
        title="调整存储空间"
        size="huge"
        :bordered="false"
        :segmented="segmented"
      >
        <div>
          <n-input v-model:value="storageNumber" max="999" min="1" style="width: 200px"/>
         <span> G</span>

          <p>当前大小：{{ showText }}</p>
        </div>
        <div class="storage-btns">
          <NSpace>
            <NButton @click="CancelMethod(0)">取消</NButton>
            <NButton type="primary" @click="DisableEnable">确定</NButton>
          </NSpace>
        </div>
      </n-modal>
    </div>
    <!-- 存储弹窗结束 -->

    <!-- 查看学校详情弹窗 -->
    <n-drawer v-model:show="active" :width="430">
      <n-drawer-content title="学校详情"
        ><div class="school-close" @click="active = false">×</div>
        <n-form :model="activeData" label-placement="left" :label-width="110">
          <n-form-item path="school_name" label="学校名称： ">
            <div class="light-green">{{activeData.school_name}}</div>
          </n-form-item>

          <n-form-item path="system_name" label="系统名称： ">
            <div class="light-green">{{activeData.system_name}}</div>
          </n-form-item>

          <n-form-item path="" label="详细地址： ">
            <div class="light-green">{{activeData.province_name+activeData.address}}</div>
          </n-form-item>

          <n-form-item path="two_domain" label="独立域名： ">
            <div class="light-green">{{activeData.two_domain}}</div>
          </n-form-item>

          <n-form-item path="username" label="管理员账号： ">
            <div class="light-green">{{activeData.username}}</div>
          </n-form-item>

          <n-form-item path="num_material" label="教材分发数： ">
            <div class="light-green">{{activeData.num_material}}</div>
          </n-form-item>

          <n-form-item path="num_course" label="课程分发数： ">
            <div class="light-green">{{activeData.num_course}}</div>
          </n-form-item>

          <n-form-item path="num_task" label="实训书分发数： ">
            <div class="light-green">{{activeData.num_task}}</div>
          </n-form-item>

          <n-form-item first path="badge" maxlength="50" label="客户端图标：">
            <n-image v-if="activeData.badge==''" width="100" src="/img/common/logo.png?v=1.0.0"/>
            <n-image v-else width="100" :src="activeData.badge"/>
          </n-form-item>

          <n-form-item path="storage_size" label="存储空间： ">
            <n-grid cols="12" item-responsive responsive="screen">
              <n-grid-item span="9">
                <div class="light-green" style="text-align:left">
                  <span>已用{{activeData.used_size}}</span>
                  <span style="padding-left:30px">剩余{{activeData.surplus_size}}</span>
                 <n-progress
                    type="line"
                    :show-indicator="false"
                    status="success"
                    :percentage="activeData.used_proportion"
                  />
                </div>
              </n-grid-item>
              <n-grid-item span="3">
                <div class="green" style="line-height: 50px;">总{{activeData.storage_size}} G</div>
              </n-grid-item>
            </n-grid>
          </n-form-item>

          <NSpace class="drawer-btns">
            <NButton @click="toAddLabel(activeData.id)">修改信息</NButton>
            <NButton type="primary" @click="active=false">确定</NButton>
          </NSpace>

        </n-form>
      </n-drawer-content>
    </n-drawer>
    <!-- 查看学校详情弹窗结束 -->

    <!-- v-if="isDistribute" -->
    <!-- 分发 -->
    <Distribute
    ref="distributeRef"
    :distribute-title="title"
    :label-type="resourceEnum.TEACHING_RESOURCE_TYPE"
    :distribute-fn="distributeTeaching"
  />
  <Distributed
    ref="DistributedContent"
    :distributed-id="DistributedID"
    :systemName="DistributedSystemName"
  />
    <EmailSetModal ref="emailSetModalRef" />
    <IPMonitorSetModal ref="ipMonitorSetModalRef" @list-update="reqList" />
    <LoginRecordsModal ref="loginRecordsModalRef" @school-list-update="reqList" />
    <CustomSchoolModal ref="customSchoolModalRef" @school-list-update="reqList" />

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { reactive, defineEmits, defineExpose, ref, h, nextTick, onMounted } from "vue";
import {
  NSpace,
  NButton,
  NTime,
  useMessage,
  useDialog
} from "naive-ui";

import accountType from "@/enumerators/account-type";
import { resStatusEnum } from "@/enumerators/http.js";
import {
  getSchoolList,
  PostSchoolUpdateStorage,
  GetSchoolDetail,
  PostWebSchooTtypeIndex
} from "@/api/school.js";
import { toRaw } from "@vue/reactivity";
import { RouterLink, useRouter } from "vue-router";
import PageLoading from "@/components/PageLoading/index.vue";

import Distribute from '@/components/SchoolDistribute/Distribute.vue';
import Distributed from '@/components/Distributed/Distributed.vue';
import EmailSetModal from './components/EmailSetModal.vue';
import IPMonitorSetModal from './components/IPMonitorSetModal.vue';
import LoginRecordsModal from './components/LoginRecordsModal.vue';
import CustomSchoolModal from './components/CustomSchoolModal.vue';

import { resourceEnum } from '@/enumerators/resource-types-map.js';
import { getTeachingList, delTeaching, batDelTeaching, distributeTeaching } from '@/api/teaching.js';
import ownUrlPermission from '@/utils/own-url-permission.js';
import { resetLoginRecords } from '@/api/school.js';

import LogoObj from '@/imgs/common/logo-uu.png';

// import {
//   getTeachingList,
//   delTeaching,
//   batDelTeaching,
// } from "@/api/teaching.js";
const message = useMessage();
const router = useRouter();
const dialog = useDialog();
const active = ref(false); // 查看学校详情是否显示
const activeData = ref([]); // 学校详情数据
// 总数据
const Datas = ref([]);
const columns = [
  {
    title: 'LOGO',
    align: 'center',
    render: ({ badge }) => h(
      'img',
      {
        style: { height: '55px', width: '55px', objectFit: 'contain' },
        src: badge || LogoObj
      }
    )
  },
  {
    title: "系统名称",
    key: "system_name",
    align: "center",
  },
  {
    title: "学校名称",
    key: "school_name",
    align: "center",
  },
  {
    title: "学生数",
    key: "student_num",
    align: "center",
  },
  {
    title: "教师数",
    key: "teacher_num",
    align: "center",
  },
  {
    title: "类型",
    align: "center",
    render({ school_type_name }) {
      if (school_type_name.includes('定制')) {
        return h('span', { style: 'color: #46aef6;' }, school_type_name);
      } else {
        return h('span', null, school_type_name);
      }
    }
  },
  {
    title: "状态",
    align: "center",
    render: (row) =>
      h("span", { style: `color: ${String(row.status) === '1' ? '#46aef6' : '#ccc'}` }, { default: () => getResourceName(row.status) }),
  },
  {
    title: "存储空间(已使用/总空间)",
    align: "center",
    render: (row) =>
      h("span", null, {
        default: () => storageSize(row.used_storage_size, row.storage_size),
      }),
  },
  {
    title: '是否监控登录IP',
    align: 'center',
    render: ({ monitor }) => {
      const isMonitor = Number(monitor) === 1;
      return h(
        'span',
        { style: `color: ${isMonitor ? '#46aef6' : '#ccc'}` },
        isMonitor ? '监控中' : '未监控'
      );
    }
  },
  {
    title: 'IP登录数',
    align: "center",
    render: ({ school_ip_login_count }) => {
      const num = Number(school_ip_login_count) || 0;
      return h(
        'span',
        { style: `color: ${num ? '#333' : '#ccc'}` },
        num ? num : '-'
      );
    }
  },
  {
    title: "独立域名",
    align: "center",
    key: "two_domain",
  },
  /*
  {
    title: "定制状态",
    align: "center",
    render({ school_custom_exists }) {
      return String(school_custom_exists) === 'true'
        ? h('span', { style: 'color: orange;' }, '已定制')
        : h('span', { style: 'color: #ccc;' }, '未定制');
    }
  },
  */
  {
    title: "操作",
    align: "center",
    width: 260,
    key: "status",
    render: (row) =>
      h(NSpace, null, {
        default: () => Operation(row),
      }),
  },
];

// 数据请求 / 分页所需--------------
const { SUCCESS } = resStatusEnum;
const loading = ref(false);
const isInitPage = ref(false)     // 如果选择项调整过则 重置页数

// 数据初始化
function updateList(data) {
  // 如果选择项调整过则 重置页数
  if(isInitPage.value){
    formValue.page = 1;
    pagination.page = 1;
  }
  isInitPage.value = false

  loading.value = true;
  // 请求列表数据
  getSchoolList(data)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        Datas.value = res.data.list;
        updateItemCount(res.data.total)
      }
    })
    .catch((reject) => {});

  storeSearchParams();
}

onMounted(() => {
  if (setSearchParams()) {
    removeSearchParamsStoreRead();
    setStoreSearchParams();
  }
  reqList();
});

// 状态
function getResourceName(status) {
  return accountType[status] || "";
}

// 状态
function storageSize(used, total) {
  return used + " / " + total || "";
}

// 选择更新后 初始化page
function ChangeTitle(value) {
  isInitPage.value = true
  // console.log('初始化page',formValue.page);
}

//操作
function Operation(row) {
  let tempArr = [];
  let arr1 = h(NButton,{ text: true, type: "error", onClick: () => toDelLabel(row) },{ default: () => "查看" })
  let arr2 = h(NButton,{ text: true, type: "info", onClick: () => CancelMethod(1, row) },{ default: () => "调整存储空间" })
  let arr3 = h(NButton,{ text: true, type: "error", onClick: () => toDistribute(row) },{ default: () => "分发" })
  let arr4 = h(NButton,{ text: true, type: "info", onClick: () => toAddLabel(row.id) },{ default: () => "编辑" })
  let arr5 = h(NButton,{ text: true, type: "info", onClick: () => operation(row) },{ default: () => "操作日志" })
  let arr6 = h(NButton,{ text: true, type: "info", onClick: () => DistributedFun(row) },{ default: () => "已开通系统" })
  // 查看
  if (ownUrlPermission('school/detail')) {
    tempArr.push(arr1);
  }
  if (ownUrlPermission('school/update')) {
    tempArr.push(arr4);
  }
  // 调整存储空间
  if (ownUrlPermission('school/update-storage')) {
    tempArr.push(arr2);
  }
  // 分发
  if (ownUrlPermission('school/distribute')) {
    tempArr.push(arr3);
  }
  // 已分发内容
  if (ownUrlPermission('school/distribute-log')) {
    tempArr.push(arr6);
  }
  if (ownUrlPermission('school/school-ip')) {
    tempArr.push(h(NButton,{ text: true, type: 'primary', onClick: () => handleIPMonitorSet(row) },{ default: () => 'IP监控设置' }));
  }
  tempArr.push(h(
    RouterLink,
    { to: `ip-monitor?id=${row.id}&name=${row.system_name}`, style: { textDecoration: 'none' } },
    {
      default: () => h(
        NButton,
        { type: 'primary', text: true },
        { default: () => 'IP监控' }
      )
    }
  ));
  tempArr.push(h(
    RouterLink,
    { to: `account?id=${row.id}&name=${row.system_name}`, style: { textDecoration: 'none' } },
    {
      default: () => h(
        NButton,
        { type: 'primary', text: true },
        { default: () => '账号管理' }
      )
    }
  ));
  /*
  if (ownUrlPermission('teacher/index')) {
    tempArr.push(h(
      RouterLink,
      { to: `teacher?id=${row.id}&name=${row.system_name}`, style: { textDecoration: 'none' } },
      {
        default: () => h(
          NButton,
          { type: 'primary', text: true },
          { default: () => '教师账号' }
        )
      }
    ));
  }
  // 操作日志
  if (ownUrlPermission('school/operation-log')) {
    tempArr.push(arr5);
  }
  */
  /*
  if (ownUrlPermission('school/monitor-log')) {
    tempArr.push(h(NButton,{ text: true, type: 'primary', onClick: () => handleOpenLoginRecords(row) },{ default: () => '登录记录' }));
  }
  if (ownUrlPermission('school/monitor-log-reset')) {
    tempArr.push(h(NButton,{ text: true, type: 'warning', onClick: () => handleResetLoginRecords(row) },{ default: () => '重置IP登录记录' }));
  }
  */
  // tempArr.push(h(NButton,{ text: true, type: 'primary', onClick: () => handleCustomSchool(row) },{ default: () => '定制信息' }));

  return tempArr;
}

const addLabelRef = ref(null);
// 编辑
function toAddLabel(id) {
  router.push({
    path: "/school/index/addedit",
    query: { id: id },
  });
}

// 操作日志
function operation(row) {
  router.push({
    path: "/school/index/operation",
    query: { id:row.id,name:row.system_name},
  });
}

// 查看学校详情
function toDelLabel(row) {
  loading.value = true;
  GetSchoolDetail({ "School[id]": row.id })
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        message.info(res.msg);
        activeData.value = res.data;
        active.value = true;
        
      }
    })
    .catch((reject) => {});
}

// 调整空间弹窗参数
const bodyStyle = ref({
  width: "500px",
});
const segmented = ref({
  content: "soft",
  footer: "soft",
});
const showModal = ref(false);
const showText = ref("");
const showID = ref("");
const storageNumber = ref(''); // 调整的存储空间大小

// 存储空间设置
function DisableEnable() {
  loading.value = true;
  let data = {
    "School[id]": showID.value,
    "School[storage_size]": storageNumber.value,
  };
  PostSchoolUpdateStorage(data)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        message.success(res.msg);
        showModal.value = false;
        // 成功后 调用接口刷新页面
        updateList();
      }
    })
    .catch((reject) => {});
}

// 调整空间取消事件
function CancelMethod(is, row) {
  showModal.value = is?true:false;
  showText.value = row.storage_size;
  showID.value = row.id;
}

// 分发
function DistributionMethod(param) {}

// 翻页 搜索参数集
var ParameterSet = {};

function reqList() {
  ParameterSet = toRaw(formValue);
  updateList(ParameterSet);
}
// 搜索
function handleSearch() {
  ParameterSet = toRaw(formValue);
  isInitPage.value = true;
  // console.log("school_type_id",ParameterSet.school_type_id)
  // console.log("status",ParameterSet.status)
  updateList(ParameterSet);
}

const pagination = reactive({
  page: 1,
  itemCount: 0,
  pageSize: 10,
  pageSizes: [10, 20, 30],
  showSizePicker: true,
});
const updateItemCount = (count) => {
  pagination.itemCount = Number(count) || 0;
};
const handlePageChange = (pageSize) => {
  pagination.pageSize = pageSize;
  formValue.page_size = pageSize;
  ParameterSet = toRaw(formValue);
  updateList(ParameterSet);
};
const handlePageSizeChange = (page) => {
  pagination.page = page;
  formValue.page = page;
  ParameterSet = toRaw(formValue);
  updateList(ParameterSet);
};

// 分页结束-------------------
// 状态
const typeOptions = (() =>
  Object.keys(accountType).map((value) => ({
    label: accountType[value],
    value,
  })))();

// 类型
const schoolTypeOptions = ref([
  // {label:"普通院校",value:0},{label:'定制化院校',value:1}
])

// 学校类型
PostWebSchooTtypeIndex().then((res) => {
  let Datas = res.data.list
  loading.value = false;
  for(var i=0;i<Datas.length;i++){
    let data = {
      "label":Datas[i].title,
      "value":Datas[i].id
    }
    schoolTypeOptions.value.push(data)
  }
})
.catch((reject) => {});

const getDefaultFormValue = () => ({
  system_name: "",
  status: '',
  school_type_id: '',
  search_school: '',
  page: 1,
  page_size: 10,
});
const formValue = reactive(getDefaultFormValue());
const getSearchParams = () => {
  const params = {};
  Object.keys(formValue).forEach((key) => {
    const value = formValue[key];
    value && (params[key] = value);
  });
  return params;
};

const emit = defineEmits(["list-update"]);

const handleClear = () => {
  Object.assign(formValue, getDefaultFormValue());
  pagination.pageSize = 10;
  handleSearch();
};

defineExpose({
  getSearchParams,
});

// 分发
const distributeRef = ref(null);

const title = ref("'分发 - '")

const toDistribute = (row) => {
  localStorage.setItem("schoolId",row.id);
  localStorage.setItem("schoolName",row.system_name);
  title.value = '分发 - ' + localStorage.getItem("schoolName")
  distributeRef.value.openModal();
};

// 分发
const DistributedContent = ref(null);
const DistributedID = ref(null)
const DistributedSystemName = ref('');
const DistributedFun= (row) => {
  DistributedID.value = row.id
  DistributedSystemName.value = row.system_name;
  localStorage.setItem("schoolId",row.id);
  localStorage.setItem("schoolName",row.system_name);
  DistributedContent.value.openModal();
};

const emailSetModalRef = ref(null);
function handleIPEmailSet() {
  emailSetModalRef.value.openEdit();
}

const ipMonitorSetModalRef = ref(null);
function handleIPMonitorSet(data) {
  ipMonitorSetModalRef.value.openEdit(data);
}

const loginRecordsModalRef = ref(null);
function handleOpenLoginRecords({ system_name, id }) {
  loginRecordsModalRef.value.openModal({ name: system_name, id });
}

function handleResetLoginRecords({ system_name, id }) {
  dialog.warning({
    title: '重置确认',
    content: `确定重置学校：“${system_name}”的IP登录记录？`,
    positiveText: '确定',
    negativeText: '取消',
    onPositiveClick: () => {
      loading.value = true;
      resetLoginRecords({
        'School[id]': id
      }).then(({ code }) => {
        if (code === SUCCESS) {
          reqList();
          message.success('重置成功');
        }
      }).finally(() => {
        loading.value = false;
      });
    },
    onNegativeClick: () => {}
  });
}

const customSchoolModalRef = ref(null);
function handleCustomSchool(row) {
  customSchoolModalRef.value.open(row);
}

const searchParamsStoreKey = 'a_school_list_search_params';
function storeSearchParams() {
  const { page, pageSize } = pagination;
  window.localStorage.setItem(
    searchParamsStoreKey,
    JSON.stringify({
      search: formValue,
      pagination: {
        page,
        pageSize
      }
    })
  );
}
function setStoreSearchParams() {
  try {
    const { search, pagination: savedPagination } = JSON.parse(window.localStorage.getItem(searchParamsStoreKey));
    Object.assign(formValue, search);
    Object.assign(pagination, savedPagination);
  } catch (e) {

  }
}
const searchParamsStoreReadKey = 'a_school_list_search_params_read'; // 0 1
function setSearchParams() {
  return Number(window.localStorage.getItem(searchParamsStoreReadKey)) === 1;
}
function removeSearchParamsStoreRead() {
  window.localStorage.removeItem(searchParamsStoreReadKey);
}
</script>

<style>
#account-management {
  height: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.account-null-btn {
  margin: -15px 0 10px;
  text-align: right;
}
.storage-btns {
  margin-top: 30px;
  float: right;
}
.storage-btns .n-button {
  width: 120px;
}
.n-card__content .n-input-number {
  display: inline-block !important;
}
.school-close {
  position: absolute;
  width: 30px;
  height: 30px;
  font-size: 40px;
  line-height: 28px;
  top: 9px;
  right: 20px;
  cursor: pointer;
}
.n-drawer-header .n-drawer-header__main {
  font-size: 20px;
  font-weight: 700;
}
.school-close + .n-form .n-form-item {
  padding-top: 5px;
}
.school-close + .n-form .n-form-item .n-form-item-blank{
  display: inline-block !important;
}
.school-close + .n-form .n-form-item .n-form-item-blank .n-image{
  float: right;
}
.light-green{
  text-align: right;
  box-sizing: border-box;
  padding-right:30px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.drawer-btns{
  padding-top: 40px;
  float: right;
}
.drawer-btns .n-button{
  width:120px
}
</style>